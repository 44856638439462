body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.desktop-container {
  margin-left: 210px;
  padding: 1rem 1rem 1rem 1rem;
}

.mobile-container {
  margin-top: 76px;
  padding: 1rem 1rem 1rem 1rem;
}

.menu-dropdown .text {
  margin: .5rem;
}

.calendar-day-cell:hover {
  background-color: #BBBBBB;
  cursor: pointer;
}

.ppl-tab {
  width: 100%
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}